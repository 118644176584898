import PropTypes from "prop-types";
import React from "react";
import styled from 'styled-components';
import { Button } from "components/atom/Button";

const StyledFormGroup = styled.div`
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  width: 800px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border: 2px solid #ccc;
  border-radius: 4px;
  position: relative; // 상대적 위치 지정
`;

const StyledFormControlContainer = styled.div`
  flex-grow: 1;
  display: flex;
  position: relative;
`;

const StyledFormControl = styled.input`
  width: 100%;
  padding: 0.75rem 3rem 0.75rem 0.75rem; // 오른쪽 패딩을 3rem으로 설정하여 버튼 공간 확보
  font-size: 16px;
  height: 40px;
  border: none;
  border-radius: 4px 0 0 4px;
`;

const CustomButton = styled(Button)`
  background-color: #5E17EB;
  color: white;
  font-size: 16px;
  padding: 0.75rem 1.5rem;
  border: none;
  // border-radius: 4px;
  cursor: pointer;
  position: absolute; // 절대 위치
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  height: 25px !important; 
`;

export const TextFieldWithButton = ({ id, placeholder, value, onChange, onButtonClick }) => {
  return (
    <StyledFormGroup>
      <StyledFormControlContainer>
        <StyledFormControl 
          type="text" 
          id={id} 
          placeholder={placeholder} 
          value={value}
          onChange={onChange}
        />
        <CustomButton onClick={onButtonClick}>
          CREATE
        </CustomButton>
      </StyledFormControlContainer>
    </StyledFormGroup>
  );
};

TextFieldWithButton.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};
