import PropTypes from "prop-types";
import React from "react";
import styled, { keyframes } from "styled-components";

// 원이 채워지고 비워지는 애니메이션
const progressAnimation = keyframes`
  0% {
    stroke-dasharray: 0 150;
  }
  50% {
    stroke-dasharray: 150 0;
  }
  100% {
    stroke-dasharray: 0 150;
  }
`;

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledCircularProgress = styled.svg`
  animation: ${rotateAnimation} 2s linear infinite; /* 2초 동안 무한 회전 */
`;

const CircleBackground = styled.circle`
  fill: none;
  stroke: transparent;
`;

const CircleProgress = styled.circle`
  fill: none;
  stroke: var(--lightaccentsblue-02);
  stroke-linecap: round;
  animation: ${progressAnimation} 4s ease-in-out infinite; /* 4초 동안 채우고 비워지는 애니메이션 */
`;

export const CircularProgress = ({ state, className }) => {
  // 상태에 따른 진행도를 계산합니다.
  const getProgress = (state) => {
    switch (state) {
      case "forty-five":
        return 45; // 45도 (12.5% 진행)
      case "ninety":
        return 90; // 90도 (25% 진행)
      case "one-hundred-and-thirty-five":
        return 135; // 135도 (37.5% 진행)
      case "one-hundred-and-eighty":
        return 180; // 180도 (50% 진행)
      case "zero":
      default:
        return 360; // 0도는 전체 회전 (100% 진행)
    }
  };

  const progress = getProgress(state);
  const radius = 24; // 반지름
  const circumference = 2 * Math.PI * radius; // 원의 둘레
  const strokeDasharray = (progress / 360) * circumference; // 360도로 나누어 비율 설정

  return (
    <StyledCircularProgress
      className={className}
      width={52} // SVG의 너비와 높이를 52로 설정
      height={52}
      viewBox="0 0 52 52"
    >
      <CircleBackground cx="26" cy="26" r={radius} strokeWidth="4" /> {/* 두께를 4로 설정 */}
      <CircleProgress
        cx="26"
        cy="26"
        r={radius}
        strokeWidth="4" // 두께를 4로 설정
        strokeDasharray={`${strokeDasharray} ${circumference - strokeDasharray}`}
      />
    </StyledCircularProgress>
  );
};

CircularProgress.propTypes = {
  state: PropTypes.oneOf([
    "forty-five",
    "zero",
    "one-hundred-and-thirty-five",
    "ninety",
    "one-hundred-and-eighty",
  ]).isRequired,
  className: PropTypes.string,
};
