import PropTypes from "prop-types";
import React from "react";
import { Typography } from "components/atom/Typography";
import styled, { css } from "styled-components";

const StyledPageTitle = styled.div`
  ${({ variant }) =>
    variant === 'h1'
      ? css`
          padding: 32px 16px;
        `
      : css`
          padding: 16px 24px;
        `}
  position: relative;
`;

export const PageTitle = ({ variant, children }) => {
  return (
    <StyledPageTitle variant={variant}>
      <Typography variant={variant}>{children}</Typography>
    </StyledPageTitle>
  );
};

PageTitle.propTypes = {
  variant: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
