import React, { useState, useEffect } from "react";
import { Button } from "components/atom/Button";
import { Typography } from "components/atom/Typography";
import { CircularProgress } from "components/atom/CircularProgress";
import { PageTitle } from "components/molecules/PageTitle";
import { TextFieldWithButton } from "components/atom/TextFieldWithButton";
import styled from "styled-components";
import roiImage from "assets/roi.png";
import logo from "assets/421SOHO_FINAL_2.png";

import building1 from "assets/buildings/building1.jpg";
import building2 from "assets/buildings/building2.jpg";
import building3 from "assets/buildings/building3.jpg";
import building4 from "assets/buildings/building4.jpg";
import building5 from "assets/buildings/building5.jpg";
import building6 from "assets/buildings/building6.jpg";
import building7 from "assets/buildings/building7.jpg";
import building8 from "assets/buildings/building8.jpg";
import building9 from "assets/buildings/building9.jpg";
import building10 from "assets/buildings/building10.jpg";
import building11 from "assets/buildings/building11.jpg";
import building12 from "assets/buildings/building12.jpg";
import building13 from "assets/buildings/building13.jpg";
import building14 from "assets/buildings/building14.jpg";
import building15 from "assets/buildings/building15.jpg";
import building16 from "assets/buildings/building16.jpg";
import building17 from "assets/buildings/building17.jpg";
import building18 from "assets/buildings/building18.jpg";

const images = [
  building1, building2, building3, building4, building5, 
  building6, building7, building8, building9, building10,
  building11, building12, building13, building14, building15,
  building16, building17, building18
];

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; // Keep as is or adjust based on your layout needs
  justify-content: flex-start; // Changed to flex-start to accommodate the header
  height: 100vh;
  position: relative;
  padding: 20px;
  overflow: auto; // Added in case content exceeds viewport height
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
`;

const Logo = styled.img`
  padding: 20px;
  height: 50px; // Set the height or width depending on your design needs
`;

const ImageDisplayArea = styled.div`
  display: flex;
  flex-direction: row; // 요소들을 가로로 배치
  align-items: start; // 상단 정렬
  justify-content: center; // 중앙 정렬
  margin-top: 20px; // 상단 여백
  gap: 40px; // 요소 사이의 간격
`;

const CustomButton = styled(Button)`
  width: 320px !important;
`;

const RoiImage = styled.img`
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: -1;
`;

const PlaceholderBox = styled.div`
  width: 520px;
  height: 520px;
  background-color: #f0f0f0;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageDisplay = styled.img`
  width: 512px;
  height: 512px;
`;

const ImageListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin-top: 48px;
`;

const ThumbnailImage = styled.img`
  width: 64px;
  height: 64px;
  cursor: pointer;
  object-fit: cover;
  border-radius: 4px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const App = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [thumbnailImages, setThumbnailImages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleGenerateClick = () => {
    const availableImages = images.filter((image) => !thumbnailImages.includes(image));
    if (availableImages.length > 0) {
      const randomIndex = Math.floor(Math.random() * availableImages.length);
      const newImage = availableImages[randomIndex];
      
      setLoading(true);  // 로딩 상태 활성화
      
      setTimeout(() => {
        setSelectedImage(newImage);
        setThumbnailImages([...thumbnailImages, newImage]);
        setLoading(false);  // 로딩 상태 비활성화
      }, 3000);  // 3초 대기 후 이미지 표시
    }
  };

  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <StyledApp>
      <Header>
        <Logo src={logo} alt="421 SOHO Logo" />
      </Header>
      <PageTitle variant="h1">AI IP Generator</PageTitle>
      <TextFieldWithButton
        id="uniqueID"
        placeholder="Roi, wearing a Santa hat, on a table"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onButtonClick={handleGenerateClick}
      />
      <ImageDisplayArea>
        <PlaceholderBox>
          {loading ? (
            <CircularProgress state="one-hundred-and-thirty-five" />
          ) : selectedImage ? (
            <ImageDisplay src={selectedImage} alt="Generated ROI" />
          ) : (
            <Typography variant="body2">
              Click the button to generate new image
            </Typography>
          )}
        </PlaceholderBox>
        <ImageListContainer>
          {thumbnailImages.map((image, index) => (
            <ThumbnailImage
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => handleThumbnailClick(image)}
            />
          ))}
        </ImageListContainer>
      </ImageDisplayArea>
      {/* <CustomButton
        status={loading ? "deactive" : "filled"}
        onClick={handleGenerateClick}
        disabled={loading}
      >
        {loading ? "Loading..." : "GENERATE"}
      </CustomButton> */}
      <RoiImage className="roi" alt="ROI" src={roiImage} />
    </StyledApp>
  );
};

export default App;
